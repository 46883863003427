import $ from 'jquery';
import 'what-input';


// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

$(function() {
    $(window).scroll(function() {
      var winTop = $(window).scrollTop();
      if (winTop >= 100) {
        $("body").addClass("sticky-shrinknav-wrapper");
      } else{
        $("body").removeClass("sticky-shrinknav-wrapper");
      }
    });
  });
  
$("#hamburger").click(function() {
  $("#menu-overlay").fadeIn();
  $('html, body').css({
    overflow: 'hidden',
    height: '100%'
  });
}); 

$("#menu-x").click(function() {
  $("#menu-overlay").fadeOut();
  $('html, body').css({
    overflow: 'auto',
    height: 'auto'
  }); 
}); 

//set active menu item
//grab current url
var url = window.location.href;

$(".sticky-shrinknav-menu2 a").each(function() {
  //check if menu item href is equal to current url
  var currentSpan = $(this).find("span");
  if (url == this.href) {
    //if so then apply the active class to that menu item
    currentSpan.addClass("active");
    return false;
  }
});
$(".mobile-nav a").each(function() {
  //check if menu item href is equal to current url
  var currentSpan = $(this).find("span");
  if (url == this.href) {
    //if so then apply the active class to that menu item
    currentSpan.addClass("active");
    return false;
  }
});

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

$("#demo").click(function() {
  demoContact($('#demo-parent1'), $('#demo-name'), $('#demo-email'));  
});

$("#demo2").click(function() {
  demoContact($('#demo-parent2'), $('#demo2-name'), $('#demo2-email'));  
});

function demoContact(parent, userName, userEmail) {
    //get user data
    var name = userName.val();
    var email = userEmail.val();

    //clear the bad input indicators
    parent.find('.form-registration-input').each(function() {
      $(this).removeClass("bad-input");
    });
  
    //validate user data
    if(name.length == 0 || email.length == 0) {
      openModal("Oops", "Please complete all fields.");
      parent.find('.form-registration-input').each(function() {
        if ($(this).val().length == 0) {
          $(this).addClass("bad-input");
        }
      });
      return;
    } else if (!validateEmail(email)){
      openModal("Oops", "The email address that you entered is invalid.");
      userEmail.addClass("bad-input");
      return;
    }
  
    //call api
    var url = "https://bioveras-request-demo.azurewebsites.net/api/sendrequestdemoemail";
    var data = {
      "name": name,
      "email": email
    }
    $.ajax(url, {
      data : JSON.stringify(data),
      dataType : "json",
      contentType : 'application/json',
      method : 'POST',
      success: function(data) {
        openModal("Thank you for your interest", "We will be in touch soon!");
        parent.find('.form-registration-input').each(function() {
          $(this).val("");
        });
      },
      error: function(data) {
        openModal("Oops", "There was a problem delivering your message. Please check your network connection and try again.");
      },
    });
}

$("#contactUs").click(function() {

  //get user data
  var firstName = $('#contact-firstName').val();
  var lastName = $('#contact-lastName').val();
  var email = $('#contact-email').val();
  var company = $('#contact-company').val();
  var message = $('#contact-message').val();

  //clear the bad input indicators
  $('.contact-form').find('.form-registration-input').each(function() {
    $(this).removeClass("bad-input");
  });

  //validate user data
  if(firstName.length == 0 || lastName.length == 0 || email.length == 0 || company.length == 0 || message.length == 0) {
    openModal("Oops", "Please complete all fields.");
    $('.contact-form').find('.form-registration-input').each(function() {
      if ($(this).val().length == 0) {
        $(this).addClass("bad-input");
      }
    });
    return;
  } else if (!validateEmail(email)){
    openModal("Oops", "The email address that you entered is invalid.");
    $('#contact-email').addClass("bad-input");
    return;
  }

  //call api
  var url = "https://bioveras-contact-us.azurewebsites.net/api/sendcontactusemail";
  var data = {
    "firstName": firstName,
    "lastName": lastName,
    "email": email,
    "company": company,
    "message": message
  }
  $.ajax(url, {
    data : JSON.stringify(data),
    dataType : "json",
    contentType : 'application/json',
    method : 'POST',
    success: function(data) {
      openModal("Thank you for your interest", "We will be in touch soon!");
      $('.contact-form').find('.form-registration-input').each(function() {
        $(this).val("");
      });
    },
    error: function(data) {
      openModal("Oops", "There was a problem delivering your message. Please check your network connection and try again.");
    },
  });

  // grecaptcha.render('recaptcha', {
  //   'sitekey' : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  //   'callback' : captchaCallback(url, data),
  //   'error-callback' : captchaErrorCallback,
  //   'theme' : 'dark'
  // });

});

function captchaCallback(url, data) {
  $.ajax(url, {
    data : JSON.stringify(data),
    dataType : "json",
    contentType : 'application/json',
    method : 'POST',
    success: function(data) {
      openModal("Thank you for your interest", "We will be in touch soon!");
      $('.form-registration-input').each(function() {
        $(this).val("");
      });
    },
    error: function(data) {
      openModal("Oops", "There was a problem delivering your message. Please check your network connection and try again.");
    },
  });
}

function captchaErrorCallback() {

  openModal("Oops", "There seems to be a problem. Please check your network connection and try again.");
}

function openModal(h1Content, pContent) {
  $('#modal').find("h1").text(h1Content);
  $('#modal').find("p").text(pContent);
  $('#modal').foundation('open');
}

